import React from 'react';
import { X } from 'react-feather';

export const Modal = ({ children, closeModal, modalLarge, className }) => {
    return (
        <div
            className='modal'
            aria-hidden="true"
            role="button"
            onClick={(event) => {
                if (event.target.classList.contains('modalBlanker')) {
                    closeModal();
                }
            }}
        >

            <div className='modalBlanker'>
                <div className={`modalContent${modalLarge ? ' modalLarge' : ''}${className ? ' '+className : ''}`}>
                    <button className='modalCloseButton' aria-label="close" onClick={() => closeModal()}><X className="reversed" size="32"/></button>
                    <div className="modalContentHolder">
                        <div className='modalContentInner'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

