import React from 'react'
import { Link } from "gatsby"
import * as Icon from 'react-feather'
import logoRev from '../images/general/logos/boomwriter_reversed.svg'
import bwWalchLogo from '../images/general/logos/bw-walch-logo-reversed.png'
import { ContactForm } from './forms' //DO NOT DELETE THIS IMPORT, seems to break CSS loading without it

const BwwFooter = () => {
  const showForm = (ev) => {
    ev.preventDefault();
    if (window.FreshworksWidget === undefined) {
      alert('Please enable performance cookies!');
    } else {
      window.FreshworksWidget('open');
    }
  }
  return (
    <>
    <footer className="footer">
      
      <div className="mainFooter">
        <div className="container mainFooter_wrapper">

          <div className="mainFooter_logo">
            <img src={logoRev} className="mainFooter_logo_img" alt="BoomWriter UK Logo" />
            <p className="small">The new name for GoWrite!</p>
          </div>

          <div className="mainFooter_social">
            <a href="https://twitter.com/boomtasticuk" className="smLink" title="Follow us on Twitter">
              <Icon.Twitter alt="Twitter" className="icn" />
            </a>
            <a href="https://www.facebook.com/boomtasticuk1" className="smLink" title="Follow us on Facebook">
              <Icon.Facebook alt="Facebook" className="icn" />
            </a>
            <a href="https://www.instagram.com/boomtasticuk/" className="smLink" title="Follow us on Instagram">
              <Icon.Instagram alt="Instagram" className="icn" />
            </a>
          </div>
          
          <nav className='mainFooter_nav mainFooter_nav1 fontWeight-bold'>
            <Link to="/" className="mainFooter_navLink">Home</Link>
            <Link to="/books" className="mainFooter_navLink">Books</Link>
            <Link to="/plan-your-book" className="mainFooter_navLink">Plan</Link>
            <Link to="/pricing" className="mainFooter_navLink">Pricing</Link>
            <Link to="/buy" className="mainFooter_navLink">Parents</Link>
            <Link to="https://boom-tastic.com" className="mainFooter_navLink">BoomTastic Blog <Icon.ExternalLink className="icn" /></Link>
            <button onClick={(ev) => showForm(ev)} className="mainFooter_navLink">Contact us</button>
          </nav>
          <nav className='mainFooter_nav mainFooter_nav2 small'>
            <Link to="/about" className="mainFooter_navLink">About Us</Link>
            <Link to="/privacy" className="mainFooter_navLink">Privacy Policy</Link>
            <Link to="/terms" className="mainFooter_navLink">Terms &amp; conditions</Link>
            <Link to="/cookies" className="mainFooter_navLink">Cookies</Link>
          </nav>
          
        </div>
      </div>

      <div className="subFooter">
        <div className="container subFooter_inner">
          <div className="subFooter_wrapper">
            <div className='subFooter_logo'>
              <img src={bwWalchLogo} alt="BW Walch" />
            </div>
            <div className="subFooter_info">
              <p className="smallText">BoomWriter is a member of the BW Walch family</p>
              <a className='footer_link smallText' href="https://bwwalch.com/">bwwalch.com</a>
            </div>
          </div>
          <p className='subFooter_copyright smallText'>&copy; 2022 BW Walch</p>
        </div>
      </div>

    </footer>
    </>
  )
}

export default BwwFooter