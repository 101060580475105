import React, {useState, useEffect} from "react"
import {Link} from "gatsby"
import Cookies from "universal-cookie"
import CookiePrefs from "./forms/cookie-prefs"

const CookieBanner = () => {

    const [showBanner, setShowBanner] = useState(false)
    const [cookiePrefs, setCookiePrefs] = useState({ performance:false, analytics:false })
    const cookieVersion = "1.0"
    const cookieExpirey = 30 * 24 * 60 * 60

    const cookies = new Cookies()
    const cookie = cookies.get("goapps_prefs")
    
    const clearAllCookies = () => {
        let all = cookies.getAll()
        Object.entries(all).forEach(([k, v]) => {
            cookies.remove(k)
        })
    }
    const checkLegacyCookies = () => {
        if (cookie === undefined || getCookieSetting('version') !== cookieVersion) {
            clearAllCookies()
        }
    }

    const updateCookie = (p, a) => {
        cookies.set(
            'goapps_prefs', 
            JSON.stringify({set:true, performance:p, analytics:a, version:cookieVersion }), 
            {maxAge:cookieExpirey, sameSite:"lax", secure:true}
        )
    }

    const getCookieSetting = (settingName) => {
        return (cookie) ? cookie[settingName] : false
    }

    useEffect(() => {
        checkLegacyCookies()
        setShowBanner(!getCookieSetting('set'))
        setCookiePrefs({ 
            performance:getCookieSetting('performance'), 
            analytics:getCookieSetting('analytics')
        })
    }, [])

    const acceptCookies = () => {
        setShowBanner(false)
        updateCookie(true, true)
        window.location.reload()
    }

    const rejectCookies = () => {
        setShowBanner(false)
        updateCookie(false, false)
        window.location.reload()
    }

    const submitForm = (e) => {
        e.preventDefault()
        var data = new FormData(e.target)
        updateCookie(data.get('performance') != null, data.get('analytics') != null)
        window.location.reload();
    }

    return (
        <>  
            {showBanner && (
                <div className="cookieBanner">
                    <h1 className="headingSmall">Cookies</h1>
                    <p>We use necessary cookies to make our website and applications work.</p>
                    <p>We'd also like to use other cookies to enhance the functionality on our site and/or improve it by measuring how you use the site.</p>
                    <p>You can find out further details on the cookies we use on our <Link to="/cookies" className="link">Cookies Policy Page</Link></p>
                    <button onClick={acceptCookies} className="cookieBanner_btn reversed-btn">Accept All</button>
                    <button onClick={rejectCookies} className="cookieBanner_btn reversed-btn">Reject All</button>
                    <div className="cookieBanner_more">
                        <CookiePrefs submitHandler={ (e) => submitForm(e) } settings={cookiePrefs} />
                    </div>
                </div>
            )}
        </>
    )
}

export default CookieBanner