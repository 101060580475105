import React from "react"

const CookiePrefs = ({submitHandler, settings}) => {

    return (
        <div className="cookieChoices">
            <p>You can choose which cookies you wish enable.</p>
            <p>Essential Cookies - these are always on</p>
            <form 
                className="basic-form" 
                onSubmit={(e) => submitHandler(e)}>
                <div className="basic-form__switch cookieChoices_option">
                    <p className="basic-form__switch-desc">Performance Cookies</p>
                    <input type="checkbox" value="performance" defaultChecked={settings.performance} className="basic-form__switch-check" id="CookiesPerformance" name="performance" />
                    <label htmlFor="CookiesPerformance" className="basic-form__switch-label">Performance Cookies</label>
                </div>
                <div className="basic-form__switch cookieChoices_option">
                    <p className="basic-form__switch-desc">Analytics Cookies</p>
                    <input type="checkbox" value="analytics" defaultChecked={settings.analytics} className="basic-form__switch-check" id="CookiesAnalytics" name="analytics" />
                    <label htmlFor="CookiesAnalytics" className="basic-form__switch-label">Analytics Cookies</label>
                </div>
                <button className="reversed-btn">Save & Close</button>
            </form>
        </div>
    )
}

export default CookiePrefs