import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

import { Modal } from './modal';
import logoImg from '../images/general/logos/boomwriter_reversed.svg';
import avatar from '../images/general/avatar.svg';

import { XCircle, MoreVertical, LogIn, ArrowRight, Lock } from 'react-feather';

export const Header = ({ position, className }) => {

    const [loginModal, setLoginModal] = useState(false);
    const [showNav, setShowNav] = useState(false);
    const [joinModal, setJoinModal] = useState(false);

    return (
        <header className={`site-header${position < -100 ? ' isScrolled' : ''}${className ? ' '+className : ''}`}>
            <Container className="p-0" fluid>
                <Row className="align-content-center mx-0">
                    <Col xs="auto" className="p-0">
                        <button className="pl-1 py-half d-xl-none nav-logo-btn d-flex align-items-center mb-0" onClick={ () => setShowNav(true) } >
                            <img className="header-logo" src={ logoImg } alt="Go Write Logo" /><MoreVertical className="icon" />
                        </button>
                        <Link to="/" className="d-none d-xl-block px-1 py-half">
                            <img className="header-logo" src={ logoImg } alt="Go Write Logo" />
                        </Link>
                    </Col>
                    <Col className="ml-auto align-items-center d-inline-flex d-xl-none p-0" xs="auto">
                        <button onClick={() => setJoinModal(true)} className="nav-link nav-join-link mb-0">
                            Join
                        </button>
                        <button onClick={() => setLoginModal(true)} className="nav-link nav-login-link">
                            Login
                        </button>
                    </Col>
                    <Col className="d-md-inline-flex ml-xl-auto p-0" xs="auto">
                        <nav className={ `site-nav${showNav ? ' active' : ''}`}>
                            <button onClick={() => setShowNav(false)} className="nav-login-toggle ml-auto d-flex align-items-center justify-content-center d-xl-none mb-0">
                                <XCircle className="icon" size={44} />
                            </button>
                            <ul className="nav-list">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" activeClassName="active">
                                        <span className="nav-link-text">Home</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link nav-live-link" to="/live" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Live!</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/write-to-raise" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Write To Raise</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/leavers" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Leavers Book</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/books" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Books</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/plan-your-book/" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Plan</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/pricing" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Pricing</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link pr-md-2" to="/buy" activeClassName="active" partiallyActive={true}>
                                        <span className="nav-link-text">Parents</span>
                                    </Link>
                                </li>
                                
                                <li className="nav-item">
                                    <button onClick={() => setJoinModal(true)} className="nav-link nav-join-link px-md-2" to="">
                                        Join
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button onClick={() => setLoginModal(true)} className="nav-link nav-login-link px-md-2" to="">
                                        Login <LogIn className="ml-quarter" />
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>

            { loginModal && (
                <Modal closeModal={() => setLoginModal(false)}>
                    <div className="loginModal">
                        <h2 className='loginModalTitle'>Login</h2>
                        <a href={process.env.PUPIL_URL} className="authLink authLinkPupil">
                            <div className='authLinkPupilImg'>
                                <img src={avatar} alt='' />
                                <h3>Pupils</h3>
                            </div>
                            <ArrowRight />
                        </a>
                        <a href={process.env.TEACHER_URL} className="authLink authLinkTeacher">
                            <h3>Teachers</h3>
                            <ArrowRight />
                        </a>
                    </div>
                </Modal>
            )}

            { joinModal && (
                <Modal closeModal={() => setJoinModal(false)}>
                    <div className="loginModal">
                        <h2 className='loginModalTitle'>Join</h2>
                        <div className="authLink authLinkDisabledPupil authLinkPupil">
                            <div className='authLinkPupilImg'>
                                <img src={avatar} alt='' />
                                <div>
                                    <h3>Pupils</h3>
                                    <p>Ask your teacher or parent for your login details.</p>
                                </div>
                            </div>
                            <Lock />
                        </div>
                        <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="authLink authLinkTeacher">
                            <div className='authLinkTeacher'>
                                <h3>Teachers</h3>
                                <p>Create your school account</p>
                            </div>
                            <ArrowRight />
                        </a>
                    </div>
                </Modal>
            )}
        </header>
    )
};

export const LandingHeader = ({ position, className, children }) => {
    return (
        <header className={`site-header${position < -100 ? ' isScrolled' : ''}${className ? ' '+className : ''}`}>
            <Container className="p-0" fluid>
                <Row className="align-content-center mx-0">
                    <Col xs="auto" className="p-0">
                        <div className="px-1 py-half">
                            <img className="header-logo" src={ logoImg } alt="Go Write Logo" />
                        </div>
                    </Col>
                    <Col className="ml-auto align-items-center d-inline-flex p-0" xs="auto">
                        { children }
                    </Col>
                </Row>
            </Container>
        </header>
    )
};
